import { useAuthStore } from '@/lib/drupal/stores/auth';
import { If, createComponent } from 'react-commons';

export default createComponent('PerDevice', {}, function PerDevice ({ slots }, props) {
  const [ authState ] = useAuthStore();

  return (
    <>
      {
        If(authState.ready && (!authState.user || !authState.user.isPremiumUser), () => (
          <>{props.children}</>
        )).EndIf()
      }
    </>
  );
});
