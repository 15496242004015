import { Button, Flex, IconFA } from 'react-commons';
import { createComponent } from 'react-commons';

import { faCircleArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useSwitchLayout } from '@/lib/stores/theme';

import style from './index.module.scss';

export default createComponent('DailyUpsellPod', { style }, function DailyUpsellPod ({ className }) {
  const switchLayout = useSwitchLayout();
  
  return (
    <div className={`Box ${className}`}>
      <Flex gap0 container alignCenter>
        <p>
          Try our new Shockwave experience today!
        </p>
        <Flex pullRight>
          <Button
            secondary small
            className='--iconRight'
            onClick={() => switchLayout('modern')}
          >
            Switch to new layout
            <IconFA icon={faCircleArrowRight} />
          </Button>
        </Flex>
      </Flex>
    </div>
  );
});
