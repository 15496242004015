import React from 'react';
import { Block, ButtonLink, Buttons, Container, createPage, IconFA, If, Slot } from 'react-commons';
import Meta from '@/components/Meta';
import Image from 'next/image';

import style from './index.module.scss';
import Pod from '@/components/Pod';
import SwGameRow, { SwGameRowSkeleton } from '@/components/SwGameRow';
import UpsellPod from '@/components/UpsellPod';
import FeaturedCarouselPod from '@/components/pods/FeaturedCarouselPod';
import CarouselPod from '@/components/pods/CarouselPod';
import GenresPod from '@/components/pods/GenresPod';
import { FeaturedCarouselProps, CharacterUpsellSlide } from '@/components/FeaturedCarousel';
import { ExtendedGameData, GameData } from '@/lib/drupal/models/Games';
import { usePageData } from '@/lib/hooks/usePageData';
import { CAROUSEL_MIN_INIT_GAMES } from '@/components/GameCarousel';
import HomeModel from '@/lib/drupal/models/Home';
import { useMetaProps } from '@/lib/hooks/useMetaProps';
import { faCircleArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useEffect } from 'react';
import { appActions, useAppStore } from '@/lib/stores/app';
import HideForSWU from '@/components/HideForSWU';
import TakeoverModel, { TakeoverCampaignData } from '@/lib/drupal/models/Takeover';
import { getZonedDate } from '@/lib/util/zonedTime';
import Announcement from '@/components/Announcement';
import { themeActions, useThemeStore } from '@/lib/stores/theme';
import { CaptureClickEvent } from '@/lib/util/captureClickEvent';
import DailyUpsellPod from '@/components/DailyUpsellPod';
import FluidLayout from '@/layouts/FluidLayout';
import AppHeader from '@/components/AppHeader';
import PlaywireUnit from '@/components/playwire/PlaywireUnit';
import { useAuthStore } from '@/lib/drupal/stores/auth';

interface ClassicIndexPageProps {
  mobileGames: ExtendedGameData[]
  featuredCarouselGames: FeaturedCarouselProps['games']
  bestCarouselGames: GameData[]
  takeover?: TakeoverCampaignData
}

interface ClassicIndexPageData {
  newDownloadGame: ExtendedGameData
  downloadGames: GameData[]
  wordGames: GameData[]
  partnerGames: GameData[]
  jigsawGames: GameData[]
  newOnlineGames: GameData[]
}

export default createPage<ClassicIndexPageProps>('ClassicIndexPage', { style }, function ClassicIndexPage ({ className }, props) {
  // This is all the lazy-loaded page data
  const { 
    pageData, 
    fetchField,
  } = usePageData<ClassicIndexPageData>({
    newDownloadGame: {
      async callback () {
        return (await HomeModel.getNewDownloadGames(1, 0))[ 0 ];
      }
    },
    newOnlineGames: {
      defaultValue: () => [],
      async callback () {
        return await HomeModel.getNewOnlineGames(CAROUSEL_MIN_INIT_GAMES, 0);
      }
    },
    downloadGames: {
      defaultValue: () => [],
      async callback () {
        return await HomeModel.getDownloadGames(CAROUSEL_MIN_INIT_GAMES, 0);
      }
    },
    wordGames: {
      defaultValue: () => [],
      async callback () {
        return await HomeModel.getWordGames(CAROUSEL_MIN_INIT_GAMES, 0);
      }
    },
    partnerGames: {
      defaultValue: () => [],
      async callback () {
        return await HomeModel.getPartnerGames(CAROUSEL_MIN_INIT_GAMES, 0);
      }
    },
    jigsawGames: {
      defaultValue: () => [],
      async callback () {
        return await HomeModel.getJigsawGames(CAROUSEL_MIN_INIT_GAMES, 0);
      }
    },
  });

  const mobileGameRows = [];
  for (let i = 0; i < props.mobileGames.length; i += 2) {
    mobileGameRows.push([
      props.mobileGames[ i ],
      props.mobileGames[ i + 1 ],
    ]);
  }

  const [ themeState, dispatchThemeState ] = useThemeStore();

  const [ appState, appDispatch ] = useAppStore();
  useEffect(() => {
    if (!appState.mobileGames.length && props.mobileGames.length) {
      appDispatch(appActions.setMobileGames(props.mobileGames));
    }
  }, [ appState.mobileGames.length, props.mobileGames, appDispatch ]);

  const metaProps = useMetaProps({
    title: 'Games | FREE Online Games & Download Games | Play Games on Shockwave.com',
    description: 'Play over 1,800 free online games. Shockwave.com offers the best puzzle games, cooking games, dress up games, car racing games, and more. New games every day!',
    keywords: 'Play Games, Online Games, Games',
    ldjson: [
      {
        '@context': 'http://schema.org',
        '@type': 'WebSite',
        'url': 'https://www.shockwave.com',
        'name': 'Shockwave.com',
        'logo': 'https://www.shockwave.com/images/shockwave.svg',
        'email': 'support@shockwavehelp.zendesk.com',
        'contactPoint': [
          {
            '@type': 'ContactPoint',
            'contactType': 'customer service',
            'url': 'https://shockwavehelp.zendesk.com/hc/en-us/requests/new',
            'email': 'support@shockwavehelp.zendesk.com'
          }
        ]
      } 
    ]
  });

  const [ authState ] = useAuthStore();

  return (
    <FluidLayout 
      useAdColumn
      classicColumnWidth
      className={className}
      pageName='index'
      pageEventSettings={{
        upsellFeaturedGameEvent: 'upsell_index_featured_game',
        upsellFeaturedGameLocation: 'featured_game_panel',
      }}
    >
      <Slot name='appHeader'>
        <AppHeader />
      </Slot>

      {/* Meta information */}
      <Meta 
        {...metaProps}
        sitemapProps={{
          priority: '1.0',
          changefreq: 'daily',
        }}
      />

      <Container>
        <Block>
          
          <FeaturedCarouselPod 
            games={props.featuredCarouselGames}
            useUpsellSlide={
              <CaptureClickEvent
                action='upsell'
                location='featured_game_panel'
                href='/unlimited'
                properties={() => ({
                  tag_name: 'upsell_index_featured_game',
                })}
              >
                <CharacterUpsellSlide 
                  className='upsell_index_featured_game'
                />
              </CaptureClickEvent>
            }
            upsellSlidePosition={3}
          >
            <Slot name='title'>Featured Games</Slot>
          </FeaturedCarouselPod>

          {
            If(!authState.user?.isPremiumUser, () => (
              <Block>
                <PlaywireUnit unit={
                  { type: 'leaderboard_atf', selectorId: 'playwire__leaderboard' }
                } />
              </Block>
            )).EndIf()
          }

          <DailyUpsellPod />

          <HideForSWU>
            <Block>
              {
                If(props.takeover, () => (
                  <a href={props.takeover.campaignUrl} rel="noreferrer" target='_blank'>
                    <div className='ClassicIndexPage__Takeover'>
                      <Image
                        src={props.takeover.bannerImgSrc}
                        alt={props.takeover.imgAltText}
                        width={728}
                        height={90}
                      />
                    </div>
                  </a>
                )).EndIf()
              }
            </Block>
          </HideForSWU>

          <CarouselPod
            initialGames={props.bestCarouselGames}
            tallImg
            hideButtons
          >
            <Slot name='title'>The Best Games On The Web!</Slot>
            <Slot name='footer'>
              <Buttons>
                <ButtonLink href='/online/all-games' secondary small className='--iconRight'>
                  See All Online Games
                  <IconFA icon={faCircleArrowRight} />
                </ButtonLink>
              </Buttons>
            </Slot>
          </CarouselPod>
          
          <Block>
            <Announcement>
              {
                If(themeState.theme === 'default', () => (
                  <>
                    You can now switch to a high-contrast theme with a button at the bottom of the page.<br />Try it out now: &nbsp;
                    <a className='RouterLink' onClick={() => dispatchThemeState(themeActions.setTheme('contrast'))}>Use High-Contrast Theme</a>
                  </>
                )).Else(() => (
                  <>
                    Want to go back to the default theme?&nbsp;
                    <a className='RouterLink' onClick={() => dispatchThemeState(themeActions.setTheme('default'))}>Use Default Theme</a>
                  </>
                )).EndIf()
              }
            </Announcement>
          </Block>

          <Pod
            lazyLoad='whenInViewport'
            onAfterLazyLoad={() => fetchField('newDownloadGame')}
          >
            <Slot name='title'>New Download Games</Slot>
            {
              If(pageData.newDownloadGame.data, () => (
                <SwGameRow 
                  {...pageData.newDownloadGame.data}
                  isDownloadGame
                />
              ))
                .Else(() => (
                  <SwGameRowSkeleton />
                )).EndIf()
            }
          </Pod>

          <CarouselPod
            tallImg
            lazyLoad='whenInViewport'
            onAfterLazyLoad={() => fetchField('newOnlineGames')}
            initialGames={pageData.newOnlineGames.data}
            fetchMoreGames={async (limit, offset) => {
              return await HomeModel.getNewOnlineGames(limit, offset);
            }}    
          >
            <Slot name='title'>New Online Games</Slot>
            <Slot name='footer'>
              <Buttons>
                <ButtonLink href='/online/all-games' secondary small className='--iconRight'>
                  See All Online Games
                  <IconFA icon={faCircleArrowRight} />
                </ButtonLink>
              </Buttons>
            </Slot>
          </CarouselPod>

          <CarouselPod 
            lazyLoad='whenInViewport'
            onAfterLazyLoad={() => fetchField('downloadGames')}
            initialGames={pageData.downloadGames.data}
            fetchMoreGames={async (limit, offset) => {
              return await HomeModel.getDownloadGames(limit, offset);
            }}              
          >
            <Slot name='title'>Download Games</Slot>
            <Slot name='footer'>
              <Buttons>
                <ButtonLink href='/download/all-games' secondary small className='--iconRight'>
                  See All Download Games
                  <IconFA icon={faCircleArrowRight} />
                </ButtonLink>
              </Buttons>
            </Slot>
          </CarouselPod>

          <CarouselPod 
            lazyLoad='whenInViewport'
            onAfterLazyLoad={() => fetchField('wordGames')}
            initialGames={pageData.wordGames.data}
            fetchMoreGames={async (limit, offset) => {
              return await HomeModel.getWordGames(limit, offset);
            }}              
          >
            <Slot name='title'>Word Games</Slot>
            <Slot name='footer'>
              <Buttons>
                <ButtonLink href='/online/word-games' secondary small className='--iconRight'>
                  See All Word Games
                  <IconFA icon={faCircleArrowRight} />
                </ButtonLink>
              </Buttons>
            </Slot>
          </CarouselPod>

          <CarouselPod 
            lazyLoad='whenInViewport'
            onAfterLazyLoad={() => fetchField('partnerGames')}
            initialGames={pageData.partnerGames.data}
            fetchMoreGames={async (limit, offset) => {
              return await HomeModel.getPartnerGames(limit, offset);
            }}              
          >
            <Slot name='title'>Exclusive Shockwave Partners</Slot>
          </CarouselPod>

          <CarouselPod 
            lazyLoad='whenInViewport'
            onAfterLazyLoad={() => fetchField('jigsawGames')}
            initialGames={pageData.jigsawGames.data}
            fetchMoreGames={async (limit, offset) => {
              return await HomeModel.getJigsawGames(limit, offset);
            }}              
          >
            <Slot name='title'>Jigsaw Games</Slot>
            <Slot name='footer'>
              <Buttons>
                <ButtonLink href='/online/jigsaw-games' secondary small className='--iconRight'>
                  See All Jigsaw Games
                  <IconFA icon={faCircleArrowRight} />
                </ButtonLink>
              </Buttons>
            </Slot>
          </CarouselPod>

          <HideForSWU>
            <CaptureClickEvent
              action='upsell'
              location='swu_panel_upsell'
              href='/unlimited'
              properties={() => ({
                tag_name: 'upsell_index_swu_promo',
              })}
            >
              <UpsellPod className='upsell_index_swu_promo' />
            </CaptureClickEvent>
          </HideForSWU>

          <GenresPod />

        </Block>
      </Container>

    </FluidLayout>
  );
});

export async function getStaticProps () {
  // Mobile games
  let mobileGames: ExtendedGameData[];
  try {
    mobileGames = await HomeModel.getMobileGames();
  } catch (err) {
    throw err;
  }

  // Featured carousel games
  let featuredCarouselGames: FeaturedCarouselProps['games'];
  try {
    featuredCarouselGames = await HomeModel.getFeaturedCarouselGames() as FeaturedCarouselProps['games'];
  } catch (err) {
    throw err;
  }

  // Best carousel games
  let bestCarouselGames: GameData[] = [];
  try {
    bestCarouselGames = await HomeModel.getBestGames(CAROUSEL_MIN_INIT_GAMES, 0);
  } catch (err) {
    throw err;
  }

  // Takeover
  let takeover: TakeoverCampaignData;
  try {
    takeover = await TakeoverModel.getCurrentCampaign();

    // Only show takeover if it's active
    const today = getZonedDate();
    const startDate = getZonedDate(takeover.startDate);
    const endDate = getZonedDate(takeover.endDate);
    if (today < startDate || today > endDate) {
      takeover = null;
    }
  } catch (err) {
    takeover = null;
  }

  // Gather props
  const props: ClassicIndexPageProps = {
    mobileGames,
    featuredCarouselGames,
    bestCarouselGames,
    takeover,
  };

  return {
    props,
    revalidate: process.env.APP_CONFIG.REVALIDATE_INTERVAL_SHORT,
  };
}