import { Reducer, useEffect, useReducer, useRef } from 'react';

enum PageDataActionType {
  SET_LOADING,
  SET_DATA,
  SET_ERROR,
}

interface PageDataAction {
  type: PageDataActionType
  payload: {
    key: string
    data?: any
  }
}

interface PageData <T> {
  isLoading: boolean
  _hasFetchedOnce: boolean
  error?: Error
  data?: T
}

type PageDataFields<T> = {
  [key in keyof T]: {
    autoFetch?: boolean
    defaultValue?: () => T[key]
    callback: () => Promise<T[key]>
  };
};

export function usePageData <T = { [key: string]: any }> (fields: PageDataFields<T>) {
  type PageDataState = {
    [key in keyof T]: PageData<T[key]>
  }

  const initialState: PageDataState = Object.keys(fields)
    .reduce((acc, key) => {
      const field = fields[ key ];
      const data: PageData<any> = {
        isLoading: field.autoFetch,
        _hasFetchedOnce: false,
        error: undefined,
        data: field.defaultValue 
          ? field.defaultValue() 
          : undefined,
      };
      acc[ key ] = data;
      return acc;
    }, {} as PageDataState);

  const [ pageData, dispatchPageData ] = useReducer<Reducer<PageDataState, PageDataAction>>((state, action) => {
    switch (action.type) {
    case PageDataActionType.SET_LOADING: {
      const item = state[ action.payload.key ];

      return {
        ...state,
        [ action.payload.key ]: {
          ...item,
          isLoading: true,
        }
      };
    }
    case PageDataActionType.SET_DATA: {
      return {
        ...state,
        [ action.payload.key ]: {
          isLoading: false,
          _hasFetchedOnce: true,
          error: undefined,
          data: action.payload.data,
        }
      };
    }
    case PageDataActionType.SET_ERROR: {
      return {
        ...state,
        [ action.payload.key ]: {
          isLoading: false,
          error: action.payload.data,
          data: undefined,
        }
      };
    }
    }
  }, initialState);

  // Methods
  const fetchField = async (key: keyof T, forceRefetch?: boolean) => {
    if (pageData[ key ].isLoading) return;

    // Don't fetch again if we already have data, unless forceRefetch is true
    if (!forceRefetch && pageData[ key ]._hasFetchedOnce) return;

    dispatchPageData({ type: PageDataActionType.SET_LOADING, payload: { key: key as string } });

    const field = fields[ key ];
    try {
      const data = await field.callback();
      dispatchPageData({ type: PageDataActionType.SET_DATA, payload: { key: key as string, data } });
    } catch (error) {
      dispatchPageData({ type: PageDataActionType.SET_ERROR, payload: { key: key as string, data: error } });
    }
  };
  
  // Auto-fetch fields
  const hasFetchedRef = useRef(false);
  useEffect(() => {
    if (hasFetchedRef.current) return;
    hasFetchedRef.current = true;

    const fetchFields = async () => {
      const fieldsToFetch = Object.keys(fields)
        .map(async (fieldName) => {
          const field = fields[ fieldName ];
          if (!field.autoFetch) return;

          return fetchField(fieldName as keyof T);
        })
        .filter((promise) => promise !== undefined);

      await Promise.all(fieldsToFetch);
    };
    fetchFields();
  });

  return {
    pageData,
    fetchField,
  } as {
    pageData: PageDataState
    fetchField: (key: keyof T, forceRefetch?: boolean) => Promise<void>
  };
}
